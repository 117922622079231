/*jshint esversion:6*/
import {
    $get,
    $post,
    $delete,
    $put
} from '@/api';
export default {
    getList(params) {
        return $get('/Label/GetLabelPageList', params);
    },
    addLabel(data) {
        return $put('/Label/Create', data);
    },
    updateLabel(data) {
        return $post('/Label/Edit', null, data);
    },
    deleteLabel(params) {
        return $delete('/Label/Delete', params);
    }
}